import React, {Component} from 'react';
import logo from '../img/bwki-competition-logo.png';
import {Link} from 'react-router-dom';
import Footer from './footer';
import auth from '../services/authService';
import './entry-page/main.css'

class EntryPage extends Component {

    render() {
        if (auth.getCurrentUser()) this.props.history.push('/');
        return (
            <React.Fragment>
                <div className={`womix__backgroundcliptext`}>
                    <section className="content entry">
                        <div className="container-fluid home-wrapper margin-top-5">
                            <div className="middle-content entry">
                                <div className={`entry-left`}>
                                    <img src={logo} alt=""/>
                                </div>
                                <div className={`entry-right`}>
                                    <div className="right-content-wrapper">
                                        <span className={`title`}>Registriere dich,<br/>setze deine KI-Idee um und<br/>gewinne tolle Preise</span>
                                        {/*<span className={`title title-secondary`}>beim Bundeswettbewerb KI ein</span>*/}
                                        <div className="Youtube-Link-Block">
                                            <Link className="button primary" to={'/auth'}>Hier geht’s lang!</Link>
                                        </div>
                                        <div className="logos-wrapper mt-3">
                                            <a href="https://www.facebook.com/bwkiaustria" target="_blank"><img
                                                className="social-logo" src={`/images/icons/media/facebook.svg`}/></a>
                                            <a href="https://www.instagram.com/bwki_at/" target="_blank"><img
                                                className="social-logo" src={`/images/icons/media/instagram.svg`}/></a>
                                            <a href="https://www.youtube.com/channel/UCl628j22u6huuu6T67fpMzA"
                                               target="_blank"><img className="social-logo"
                                                                    src={`/images/icons/media/youtube.svg`}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content entry">
                        <div className="container-fluid home-wrapper margin-top-5">
                            <div className="middle-content additional-info entry">
                                <span className={`title`}>DER WETTBEWERB</span>
                                <span>
                                    Für den Bundeswettbewerb reichen Teams von bis zu fünf Schüler*innen eine Projektidee
                                    zu einer
                                    beliebigen Problemstellung ein, die sie mit Künstlicher Intelligenz lösen wollen. In
                                    der
                                    folgenden Zeit arbeiten die Teams eigenständig die Idee aus und erstellen ein
                                    funktionsfähiges
                                    Programm, gegebenenfalls einen Prototypen und einen Video-Pitch.
                                    Die vielversprechendsten Teams werden zur Preisverleihung eingeladen und präsentieren
                                    online
                                    ihre Idee. Eine Jury ermittelt und prämiert die besten Projekte.
                                    <br/><br/>
                                    Das fertige Konzept (Programm-Code, schriftliche Dokumentation und Präsentations-Video)
                                    muss
                                    auf der Plattform bis zum 01.10.2024 eingereicht werden.
                                    <br/><br/>
                                </span>
                                <span className={`title`}>DIE FRISTEN</span>
                                <span>
                                    <b>Ideeneinreichung:</b> bis 31.07.2024 möglich<br/>
                                    <b>Ende der Projekteinreichung:</b> 01.10.2024<br/>
                                    <b>Finale:</b> KW44/45 (nähere Informationen folgen noch)
                                    <br/><br/>
                                    Vernetzt euch mit Gleichgesinnten und<br/>
                                    tretet unserer BWKI Community auf Discord bei:<br/>
                                    <a href={'https://discord.gg/hjGQxd3Qwj'}>https://discord.gg/hjGQxd3Qwj</a>
                                    <br/><br/>
                                    Und folgt uns auch auf Instagram für mehr Infos:<br/>
                                    <a href={'https://www.instagram.com/bwki_at/'}>https://www.instagram.com/bwki_at/</a>
                                    <br/>
                                    <br/>
                                </span>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </React.Fragment>
        );
    }
}

export default EntryPage;
