import React, { useState, useEffect } from 'react';
import { FilePond, File } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import Checkbox from "../common/checkbox";


const SubmitProjectForm = ({ data, loading, showImageError, imageError, handleChange, handleTextChange, handleCharChange, validate, loadingError, user, showConfirmationPopup, handleSave, projectSubmitted, existingFileNames, showSaved, showCompleted, handleFinaleAbgabe }) => {

    const { teamname, mitglieder, titel, titel_count, ziel, ziel_count, motivation, motivation_count,
        anwendung, anwendung_count, datensatz, datensatz_count, vorbereitung, vorbereitung_count,
        dnn, dnn_count, evaluation, evaluation_count, produkt, produkt_count, komponenten, komponenten_count,
        anleitung, anleitung_count, probleme, probleme_count, ethnic, ethnic_count, potential, potential_count, schwachstelle, finaleAbgabe, schwachstelle_count,
        zukunft, zukunft_count, code, files, video } = data;
    return (
        <div className="custom-form-wrapper">
            <div className="row">
                <div className='col-md-12'>
                    <h5>Euer Projekt ist fertig! Wir sind super gespannt, mit welchem tollen KI-Projekt ihr uns überraschen werdet!</h5>
                    <br />
                    <h3 className="primary-color-lightgreen-foreground">Euer Projekt muss bis spätestens 01.10.2024 23:59 eingereicht werden.</h3>
                    <br />
                    <div className='header2 font-sub-header text-justify'>
                        <span>Nehmt euch genug Zeit für das Ausfüllen der Fragen. Am Ende der Dokumentation ladet ihr euren Video-Pitch hoch. Der Video-Pitch sollte 3 - 5 Minuten lang sein und  ihr solltet  allgemein verständlich euer Projekt bewerben. Stellt euer Team vor, nennt eure Motivation und das Ziel eures Projektes, zeigt, was ihr geleistet habt und worin die technische Raffinesse eures Projekts liegt und präsentiert das finale Ergebnis. Denkt daran: Die ersten 30 Sekunden sind am wichtigsten!</span>
                        <br />
                        <span>
                            Wir werden für die Auswahl folgende Kriterien berücksichtigen:<br />
                    &nbsp;&nbsp;- Praktische Relevanz und Innovationskraft<br />
                    &nbsp;&nbsp;- Eingesetzte Methoden des maschinellen Lernens<br />
                    &nbsp;&nbsp;- Euer Ergebnis<br />
                    &nbsp;&nbsp;- Kritische Reflexion des eigenen Projekts<br />
                    &nbsp;&nbsp;- Die Präsentation eures Projekts im Video-Pitch<br />
                        </span>
                    </div>
                </div>
            </div>
            <form onSubmit={showConfirmationPopup} >
                <div className="row mt-5">
                    <div className="form-group col-md-12 teamname">
                        <label for='labelDesc'>Team</label>
                        <input className='form-control' name='mitglieder' id='mitglieder' readOnly value={teamname} onChange={handleChange} type="text" />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-12 mitglieder">
                        <label for='labelDesc'>Alle Mitglieder des Teams mit vollem Namen und Benutzernamen</label>
                        <input className='form-control' name='mitglieder' id='mitglieder' readOnly value={mitglieder} onChange={handleChange} type="text" />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group titel col-md-12">
                        <label for='labelDesc'>Projekttitel (max. 150 Zeichen inkl. Leerzeichen)</label>
                        <input className='form-control' name='titel' id='titel' value={titel} readOnly={projectSubmitted} onChange={handleCharChange('titel', 150)} type="text" maxLength="200" />
                        <span className='float-right'>Zeichen: {titel_count}/150</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12 header subheader">
                        <h2 className="primary-color-lightgreen-foreground">Praktische Relevanz und Innovationskraft</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group ziel col-md-12">
                        <label for='labelDesc'>Was ist das Ziel des Projekts? (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='ziel' id='ziel' value={ziel} readOnly={projectSubmitted} onChange={handleCharChange('ziel', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {ziel_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group motivation col-md-12">
                        <label for='labelDesc'>Warum ist euer Projekt wichtig? (max. 330 Zeichen inkl. Leerzeichen )</label>
                        <textarea className='form-control' name='motivation' id='motivation' readOnly={projectSubmitted} value={motivation} onChange={handleCharChange('motivation', 330)} maxLength="330"></textarea>
                        <span className='float-right'>Zeichen: {motivation_count}/330</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group anwendung col-md-12">
                        <label for='labelDesc'>Wer kann euere Ergebnisse verwenden? Was ist der Anwendungsfall? (max. 260 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='anwendung' id='anwendung' readOnly={projectSubmitted} value={anwendung} onChange={handleCharChange('anwendung', 260)} maxLength="260"></textarea>
                        <span className='float-right'>Zeichen: {anwendung_count}/260</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-12">
                        <h2 className="primary-color-lightgreen-foreground">Eingesetzte Methoden</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group datensatz col-md-12">
                        <label for='labelDesc'>Beschreibung eures Datensatzes (Herkunft, Größe, Klassen...) (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='datensatz' id='datensatz' readOnly={projectSubmitted} value={datensatz} onChange={handleCharChange('datensatz', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {datensatz_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group vorbereitung col-md-12">
                        <label for='labelDesc'>Aufbereitung der Daten und sonstige Vorbereitungen (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='vorbereitung' id='vorbereitung' readOnly={projectSubmitted} value={vorbereitung} onChange={handleCharChange('vorbereitung', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {vorbereitung_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group dnn col-md-12">
                        <label className='labelDesc'>Beschreibung eurer Methoden (Aufbau neuronales Netz, machine learning model, Trainieren und Testen der Daten...) (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='dnn' id='dnn' readOnly={projectSubmitted} value={dnn} onChange={handleCharChange('dnn', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {dnn_count}/400</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="primary-color-lightgreen-foreground">Euer Ergebnis</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group evaluation col-md-12">
                        <label className='labelDesc'>Wie habt ihr euer Projekt ausgewertet? Welche Genauigkeit habt ihr auf Trainingsdaten und auf Testdaten erreicht? (max. 1000 Zeichen)</label>
                        <textarea className='form-control' name='evaluation' id='evaluation' readOnly={projectSubmitted} value={evaluation} onChange={handleCharChange('evaluation', 1000)}></textarea>
                        <span className='float-right'>Zeichen: {evaluation_count}/1000</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group produkt col-md-12">
                        <label for='labelDesc'>Was habt ihr entwickelt? (max. 260 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='produkt' id='produkt' readOnly={projectSubmitted} value={produkt} onChange={handleCharChange('produkt', 260)} maxLength="260"></textarea>
                        <span className='float-right'>Zeichen: {produkt_count}/260</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group komponenten col-md-12">
                        <label for='labelDesc'>Was benötigt man, um euer Ergebnis nutzen zu können? (Handy, GitHub, Internet, Rasberry Pi...) (max. 260 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='komponenten' id='komponenten' readOnly={projectSubmitted} value={komponenten} onChange={handleCharChange('komponenten', 260)} maxLength="260"></textarea>
                        <span className='float-right'>Zeichen: {komponenten_count}/260</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group anleitung col-md-12">
                        <label for='labelDesc'>Anleitung für den Nutzer (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='anleitung' id='anleitung' readOnly={projectSubmitted} value={anleitung} onChange={handleCharChange('anleitung', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {anleitung_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <h2 className="primary-color-lightgreen-foreground">Kritische Reflexion des eigenen Projekts</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group probleme col-md-12">
                        <label for='labelDesc'>Auf welche Probleme seid ihr gestoßen? (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='probleme' id='probleme' readOnly={projectSubmitted} value={probleme} onChange={handleCharChange('probleme', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {probleme_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group potential col-md-12">
                        <label for='labelDesc'>Was ist das größte Potential eures Projekts? (max. 260 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='potential' id='potential' readOnly={projectSubmitted} value={potential} onChange={handleCharChange('potential', 260)} maxLength="260"></textarea>
                        <span className='float-right'>Zeichen: {potential_count}/260</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group schwachstelle col-md-12">
                        <label for='labelDesc'>Was ist die größte Schwachstelle eures Projekts? (max 260 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='schwachstelle' id='schwachstelle' readOnly={projectSubmitted} value={schwachstelle} onChange={handleCharChange('schwachstelle', 260)} maxLength="260"></textarea>
                        <span className='float-right'>Zeichen: {schwachstelle_count}/260</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group zukunft col-md-12">
                        <label for='labelDesc'>Wie würdet ihr euer Projekt vorantreiben, wenn ihr unendlich viele Ressourcen und Zeit hättet? (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='zukunft' id='zukunft' readOnly={projectSubmitted} value={zukunft} onChange={handleCharChange('zukunft', 400)} maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {zukunft_count}/400</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group zukunft col-md-12">
                        <label htmlFor='labelDesc'>Welche ethnischen Bedenken könnte man bei eurem Projekt haben und wie geht ihr damit um? (z.B. Datenschutz, Arbeitsplätze, Diskriminierung, etc.) (max. 400 Zeichen inkl. Leerzeichen)</label>
                        <textarea className='form-control' name='ethnic' id='ethnic' readOnly={projectSubmitted}
                                  value={ethnic} onChange={handleCharChange('ethnic', 400)}
                                  maxLength="400"></textarea>
                        <span className='float-right'>Zeichen: {ethnic_count}/400</span>
                    </div>
                </div>
                { !projectSubmitted && <div className='row'>
                    <div className="col-md-12">
                        <h2 className="primary-color-lightgreen-foreground">Die Präsentation des Projekts in einem Video Pitch</h2>
                    </div>
                </div> }
                { !projectSubmitted && <div className='row'>
                    <div className='col-md-12'>
                        <p className="text-justify"> In dem Video-Pitch habt ihr
                        die Möglichkeit uns von eurer Präsentationsfähigkeit und Originalität zu überzeugen! Der Video-Pitch sollte mindestens
                        3 Minuten und maximal 5 Minuten
                        dauern (alles, was länger ist, wird nach 5 Minuten abgebrochen). In dem Pitch solltet ihr allgemein verständlich
                        euer Projekt bewerben. Stellt euer Team vor, nennt eure Motivation und das Ziel eures Projektes,
                        zeigt, was ihr geleistet habt und worin die technische Raffinesse eures Projekts liegt und präsentiert das finale
            Ergebnis. Denkt daran: Die ersten 30 Sekunden sind am wichtigsten!</p>
                    </div>
                </div> }
                {!projectSubmitted && <div className='row'> <div className="form-group video col-md-12 mb-0">
                    <label for='labelDesc' style={{ float: 'none' }}>Länge: mindestens 3 Minuten, maximal 5 Minuten (max. 500MB) </label>
                    <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        beforeAddFile={(file) => { if ((file.fileSize / 1000000) > 500) { showImageError(true); return false; } else showImageError(false) }}
                        onupdatefiles={(fileItems) => {
                            handleChange({ currentTarget: { type: 'file', name: 'video', value: fileItems.map(fileItem => fileItem.file) } })
                        }
                        } />
                    
                </div></div>}
                {imageError && <div className='alert alert-danger'>Ihr könnt Dateien bis zu maximal 500MB hochladen.</div>}
                {loadingError && <div className='alert alert-danger'>{loadingError}</div>}

                {!projectSubmitted && <div className='row'> <div className="col-md-12">
                    <h2 className="primary-color-lightgreen-foreground">Code</h2>
                </div></div>}
                {!projectSubmitted && <div className='row'> <div className="form-group code col-md-12 mb-0">
                    <label for='labelDesc' className='text-justify' style={{ float: 'none' }}>Bitte ladet hier euren ausführbaren Code mit readme Datei hoch.(max. 50MB)</label>
                    <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        beforeAddFile={(file) => { if ((file.fileSize / 1000000) > 50) { showImageError(true); return false; } else showImageError(false) }}
                        onupdatefiles={(fileItems) => {
                            handleChange({ currentTarget: { type: 'file', name: 'code', value: fileItems.map(fileItem => fileItem.file) } })
                        }
                        } />
                   
                </div></div>}
                {imageError && <div className='alert alert-danger'>Ihr könnt Dateien bis zu maximal 50MB hochladen.</div>}
                {loadingError && <div className='alert alert-danger'>{loadingError}</div>}

                {!projectSubmitted && <div className='row'> <div className="col-md-12">
                    <h2 className="primary-color-lightgreen-foreground">Zusätzliche Dateien (optional)</h2>
                </div></div>}
                {!projectSubmitted && <div className='row'> <div className="col-md-12 datei">
                    <label for='labelDesc' style={{ float: 'none' }}>Hier könnt ihr zusätzliche Dateien hochladen. Interessant sind zum Beispiel eine exemplarische Darstellung des Datensatzes, relevante Grafiken, Fotos von eventuell entwickelter Hardware oder Benutzerschnittstellen. (max. 5 Dateien, 20MB pro Datei)</label>
                    <FilePond
                        allowMultiple={true}
                        maxFiles={5}
                        beforeAddFile={(file) => { if ((file.fileSize / 1000000) > 20) { showImageError(true); return false; } else showImageError(false) }}
                        onupdatefiles={(fileItems) => {
                            handleChange({ currentTarget: { type: 'file', name: 'files', value: fileItems.map(fileItem => fileItem.file) } })
                        }
                        } />
                  
                </div></div>}
                {imageError && <div className='alert alert-danger'>Ihr könnt Dateien bis zu maximal 20MB hochladen.</div>}
                {loadingError && <div className='alert alert-danger'>{loadingError}</div>}

                {existingFileNames && existingFileNames.length > 0 &&
                    <div className="uploaded-files">
                        <h2 className="primary-color-lightgreen-foreground">Hochgeladene Dateien</h2>
                        <div className="pdf existing-files">
                            {existingFileNames.map(file => (
                                <div className="file-info">
                                    <span>{file}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className="col-md-12">
                        <h2 className="primary-color-lightgreen-foreground">Finale Abgabe</h2>
                    </div>
                </div>
                <Checkbox name="finaleAbgabe" tooltip={true} message="Finale Abgabe: erst wenn ihr dieses Häcken setzt, wird eure Abgabe akzeptiert, danach könnt ihr diese jedoch nicht mehr ändern" label="Finale Abgabe" id="finaleAbgabe" value={finaleAbgabe} onChange={handleFinaleAbgabe} disabled={projectSubmitted} horizontal={false} />
                <div className="row">
                    <div className='col-md-12 mt-3'>
                        <small> <strong>** Finale Abgabe: erst wenn ihr dieses Häcken setzt, wird eure Abgabe akzeptiert, danach könnt ihr diese jedoch nicht mehr ändern **</strong></small><br/>
                    </div>
                </div>
                <br/>
                {!showCompleted && !projectSubmitted && <button className="btn btn-primary" disabled={projectSubmitted || loading} onClick={handleSave} name="save">Speichern *</button>}
                &nbsp;&nbsp;&nbsp;{!showCompleted && !projectSubmitted && <button name="submit" disabled={projectSubmitted || (loading ? loading : validate())} className="btn btn-primary">{loading ? "Loading..." : "Absenden"}</button>}
                <div className="row">
                    <div className='col-md-12 mt-3'>
                        <small> <strong>** Bitte beachtet: Alle hochgeladenen Dateien werden erst gespeichert, wenn ihr euer Projekt absendet! Sofern ihr euer Projekt zwischen speichert müsst ihr die Dateien vor dem Absenden erneut hochladen. **</strong></small>
                    </div>
                </div>
                {/* <div style={{ color: 'red' }} >*Dateien werden nur beim Einreichen und nicht beim Speichern hochgeladen</div>
                    <div className="imp-info" >Sollten Probleme bei der Einreichung auf der Online-Plattform auftauchen, könnt ihr uns Euren Text, Euer Video, Euren Code und optional eine Zusatzdatei per Email oder WeTransfer an bwki@asai.ac.at bis zum 4.10.2020 senden.</div> */}

                {
                    showCompleted &&
                    <div className="alert alert-success">
                        Sie haben Ihr Projekt erfolgreich eingereicht.
                        </div>
                }

                {
                    showSaved &&
                    <div className="alert alert-success">
                        Sie haben Ihr Projekt erfolgreich gespeichert.
                        </div>
                }
                <div className="row">
                    <div className='col-md-12 mt-5'>
                        <p className='text-center mt-5'>Probleme? Dann schreibt uns: <a href='mailto:bwki@asai.ac.at'><b>bwki@asai.ac.at</b></a></p>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SubmitProjectForm;